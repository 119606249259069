<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="closeDialog()"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <template v-if="p01">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="d-flex gray-box">
            <div class="form-inp sm">
              <v-select
                  :items="this.$store.getters['userStore/GE_USER_ROLE'].company"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="회사구분"
                  placeholder="선택하세요"
                  v-model="params.id"
                  readonly
              >
              </v-select>
            </div>
            <div class="form-inp ml-2">
              <v-select
                :items="TWB151"
                outlined
                hide-details
                label="템플릿 타입"
                placeholder="선택하세요"
                v-model="newContent.NEW_TMPLAT_CD"
                required
              >
                <template v-slot:label>
                  템플릿 타입
                  <v-icon  color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <v-text-field
              class="form-inp ml-2"
              name="name"
              label="템플릿명"
              outlined
              v-model="newContent.NEW_TITL"
              required
              hide-details
            >
              <template v-slot:label>
                  템플릿명
                  <v-icon  color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="mt-2">
            <!-- 에디터 넣기-->
            <ckeditor v-model="newContent.NEW_CONTN" :config="editorConfig"></ckeditor>
          </div>
        </v-form>
      </template>
      <template v-if="p02">
        <div class="">
          <div class="table-form">
            <table>
                <colgroup>
                  <col width="127px" />
                  <col width="310px" />
                  <col width="127px" />
                  <col width="310px" />
                  <col width="127px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">등록자소속</th>
                    <td>{{detailContent.DETAIL_REGR_ATTR_DIV_NM}}</td>
                    <th scope="row">등록자</th>
                    <td>{{detailContent.DETAIL_REGR_NM}}</td>
                    <th scope="row">등록일시</th>
                     <td>{{detailContent.DETAIL_REG_DTTM}}</td>
                  </tr>
                  <tr>
                    <th scope="row">수정자소속</th>
                    <td>{{detailContent.DETAIL_AMDR_ATTR_DIV_NM}}</td>
                    <th scope="row">수정자</th>
                    <td>{{detailContent.DETAIL_AMDR_NM}}</td>
                    <th scope="row">수정일시</th>
                    <td>{{detailContent.DETAIL_UPD_DTTM}}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span v-if="!updateEditor" >템플릿명</span>
                      <v-badge v-if="updateEditor" dot color="#EF9191">템플릿명</v-badge>
                    </th>
                    <td colspan="2">
                      <div v-if="updateEditor">
                        <v-text-field
                          class="form-inp full"
                          name="name"
                          label=""
                          outlined
                          hide-details
                          v-model="detailContent.DETAIL_TITLE"
                      >
                      </v-text-field>
                      </div>
                      <div v-else>
                        {{detailContent.DETAIL_TITLE}}
                      </div>
                    </td>
                    <th scope="row">
                      <span v-if="!updateEditor" >템플릿 타입</span>
                      <v-badge v-if="updateEditor" dot color="#EF9191">템플릿 타입</v-badge>
                    </th>
                    <td colspan="2">
                       <div v-if="updateEditor" class="form-inp sm ml">
                          <v-select
                            :items="TWB151"
                            outlined
                            hide-details
                            label=""
                            placeholder="선택하세요"
                            v-model="detailContent.DETAIL_TMPLAT_TYPE_CD"
                            required
                          >
                          </v-select>
                        </div>
                        <div v-else>
                          {{detailContent.DETAIL_TMPLAT_TYPE_NM}}
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" class="pa-0">
                       <div v-if="updateEditor">
                         <ckeditor v-model="detailContent.DETAIL_CONTN" :config="editorConfig" :read-only="false">
                         </ckeditor>
                       </div>
                       <div v-else>
                        <ckeditor :value="restoreXSS_CKeditor(detailContent.DETAIL_CONTN)" :config="editorConfig" :read-only="true"></ckeditor>
                       </div>
                    </td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="p03">
          <ckeditor :value="restoreXSS_CKeditor(params.contn)" :config="readEditorConfig" :read-only="true"></ckeditor>
      </template>
      </div>
      <div class="text-right mt-3">
        <template v-if="p01">
          <v-btn v-if="mixin_set_btn($options.name, 'btnSave1')" outlined class="btn-point " @click="newTmplatInsert('I', '')">저장</v-btn>
        </template>
        <template v-if="p02">
            <v-btn v-if="updateEditor && mixin_set_btn($options.name, 'btnSave2')" outlined class="btn-point" @click="newTmplatInsert('U', detailContent.DETAIL_ID)">저장</v-btn>
            <v-btn v-if="!updateEditor && mixin_set_btn($options.name, 'btnMody')" outlined class="btn-default" @click="updateEditor = true">수정</v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil.js"
import {mixin} from "../../mixin/mixin.js"

export default {
  name: "MENU_M510301P01",
  mixins:[mixin],
  props: {
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    p03: {
      type: Boolean,
      default: false,
    },
    p04: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
    },
    detailContent: {
      type: Object,
    },

    headerTitle: {
      type: String,
      default: '제목',
    },


    TWB151 : [],

  },
  data: () => ({
    valid: true,
    footerHideTitle: '닫기',

    //p01 신규
    newContent : {
      NEW_TMPLAT_CD : "",
      NEW_TITL : "",
      NEW_CONTN : "",
      NEW_TMPLATID: "",
    },

    updateEditor : false,

    editorConfig: {
        toolbarGroups: [
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles' ] },
          { name: 'styles', groups: [ 'styles' ] },
          { name: 'colors', groups: [ 'colors' ] },
          { name: 'insert', groups: [ 'insert' ] },
          { name: 'links', groups: [ 'links' ] },
          { name: 'tools', groups: [ 'tools' ] },
          { name: 'about', groups: [ 'about' ] }
        ],
        removeButtons: "About",
        height: "500px",
      },

    readEditorConfig: {
      toolbarGroups: [
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'tools', groups: [ 'tools' ] },
      ],
      //removeButtons: "About",
      //removeButtons :'Save,NewPage,Print,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Language,Flash,PageBreak,Iframe,Templates,Anchor,Link,Unlink,Preview,Source,About,Redo,Undo,Cut,Copy,Paste,PasteText,PasteFromWord,Bold,Italic,Underline,Strike,Subscript,Superscript,NumberedList,CopyFormatting,Outdent,Blockquote,JustifyLeft,BidiLtr,Styles,Image,Find,Replace,ShowBlocks,TextColor,BGColor,Maximize,Table,HorizontalRule,Smiley,SpecialChar,Font,Format,FontSize,BidiRtl,JustifyCenter,JustifyRight,JustifyBlock,Indent,BulletedList,RemoveFormat',
      height: "600px",
      toolbarCanCollapse:  true,
      toolbarStartupExpanded: false,
    },
  }),

  created(){

  },
  watch:{

  },
  methods: {
    closeDialog(){
      this.$emit('hide');
    },
    newTmplatInsert(dataFlag, tmplatId){
      let paramObj = {
        id : tmplatId,
        flag : dataFlag
      }
      if(dataFlag == "I"){
        //템플릿 고유키 생성 후 템플릿 등록
        if(this.newContent.NEW_TMPLAT_CD == "" || this.newContent.NEW_TMPLAT_CD == null){
          this.common_alert('템플릿 타입을 선택해주세요', 'error');
          return;
        } else if (this.newContent.NEW_TITL == "" || this.newContent.NEW_TITL == null){
          this.common_alert('템플릿명을 입력해주세요', 'error');
          return;
        } else {
          this.common_confirm('저장 하시겠습니까?', this.newTmplatRegist, paramObj, null, null , 'chk');
        }


      } else if (dataFlag == "U"){
        if(this.detailContent.DETAIL_TMPLAT_TYPE_CD == "" || this.detailContent.DETAIL_TMPLAT_TYPE_CD == null){
          this.common_alert('템플릿 타입을 선택해주세요', 'error');
          return;
        } else if (this.detailContent.DETAIL_TITLE == "" || this.detailContent.DETAIL_TITLE == null){
          this.common_alert('템플릿명을 입력해주세요', 'error');
          return;
        } else {
          this.common_confirm('수정 내용을 저장 하시겠습니까?', this.newTmplatRegist, paramObj, null, null , 'chk');
        }
      }
    },
    async newTmplatRegist(paramObj){
      this.newContent.NEW_TMPLATID = await this.mixin_common_bizSeq_get("KMT");

      let newTmplatId = paramObj.id;
      let newDataFlag = paramObj.flag;

      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/km/template/regist/process";
      requestData.headers["SERVICE"] = 'km.template.regist';
      requestData.headers["METHOD"] = "process";
      requestData.headers["ASYNC"] = false;


      if(newDataFlag == "I" ){
        //저장
        requestData.sendData["TMPLAT_ID"] = "";                                                  //템플릿ID
        requestData.sendData["TMPLAT_TYPE_CD"] = this.newContent.NEW_TMPLAT_CD;                  //템플릿 구분코드
        requestData.sendData["TITL"] = this.newContent.NEW_TITL;                                 //제목
        requestData.sendData["CONTN"] = this.restoreXSS_CKeditorDec(this.newContent.NEW_CONTN);  //내용
        requestData.sendData["USE_YN"] = "Y";                                                    //사용여부 (default)
        requestData.sendData["REGR_DEPT_CD"] = "";                                               //등록자 부서코드
        requestData.sendData["REGR_ID"] = this.$store.getters['userStore/GE_USER_ID'];           //등록자ID
        requestData.sendData["DATA_FLAG"] = newDataFlag;                                         //데이터플래그 상태 (생성 : I, 수정 : U)
        requestData.sendData["KEY_VER_SEQ"] = this.newContent.NEW_TMPLATID;
        requestData.sendData["ASP_NEWCUST_KEY"] = this.params.id                                    //고객사

      } else if (newDataFlag == "U"){
        //수정
        requestData.sendData["TMPLAT_ID"] = newTmplatId;                                              //템플릿ID
        requestData.sendData["TMPLAT_TYPE_CD"] = this.detailContent.DETAIL_TMPLAT_TYPE_CD;            //템플릿 구분코드
        requestData.sendData["TITL"] = this.detailContent.DETAIL_TITLE;                               //제목
        requestData.sendData["CONTN"] = this.restoreXSS_CKeditorDec(this.detailContent.DETAIL_CONTN); //내용
        requestData.sendData["AMDR_DEPT_CD"] = "";                                                    //수정자 부서코드
        requestData.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ID'];                //수정자ID
        requestData.sendData["DATA_FLAG"] = newDataFlag;                                              //데이터플래그 상태 (생성 : I, 수정 : U)
    //  requestData.sendData["KEY_VER_SEQ"] = this.newContent.NEW_TMPLATID;

      }

      //처리자 정의 필요
      //requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId; //처리자ID

      await this.common_postCall(requestData);

      //창 닫기
      this.$emit('hide');
      this.$emit('select');

    },
  },
  computed : {
  }
}
</script>

<style>

</style>